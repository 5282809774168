<template>
  <section class="admin-orders tile">
    <h2 class="mb-15">Mass Order Update</h2>
    <p>
      Copy and paste a list of ORDER or ORDER ITEM ids you want to update.
      <br />Be sure that each ID is on its own row.
      <br />
      <span
        class="text__red"
      >By clicking submit you are changing every item you uploaded in one batch operation.</span>
    </p>
    <simplebar class="major--overflow" data-simplebar-auto-hide="false">
      <div class="admin-quick--search">
        <label class="align-top mt-15">
          Order (Item) Numbers
          <textarea rows="5" placeholder="order numbers" v-model="form.orders"></textarea>
        </label>
        <label class="align-top">
          Uploads
          <p class="text__center">
            {{ parsedIds.length }}
            <span class="text__normal">uploaded</span>
          </p>
        </label>
        <label>
          Update Type
          <select v-model="form.orderType">
            <option value></option>
            <option value="Order">Order</option>
            <option value="Order Item">Order Item</option>
          </select>
        </label>
        <label>
          {{ form.orderType === 'Order' ? 'Order' : 'Item' }} Status
          <select v-model="form.status">
            <option value></option>
            <option v-if="form.orderType !== 'Order'" value="Pending">Pending</option>
            <option v-if="form.orderType !== 'Order'" value="Completed">Completed</option>
            <option v-if="form.orderType === 'Order'" value="Ordered">Ordered</option>
            <option v-if="form.orderType === 'Order'" value="In Progress">In Progress</option>
            <option v-if="form.orderType === 'Order'" value="Awaiting Input">Awaiting Input</option>
            <option v-if="form.orderType === 'Order'" value="Ready to Ship">Ready to Ship</option>
            <option v-if="form.orderType === 'Order'" value="Sent">Sent</option>
            <option v-if="form.orderType === 'Order'" value="Beer">Beer</option>
            <option v-if="form.orderType === 'Order'" value="Pre-Order">Pre-Order</option>
            <option v-if="form.orderType === 'Order'" value="Complete">Complete</option>
            <option v-if="form.orderType === 'Order'" value="Admin Complete">Admin Complete</option>
          </select>
        </label>
        <label v-if="form.orderType === 'Order'">
          Update Ticket?
          <select v-model="form.shouldUpdate">
            <option value></option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </label>
        <label v-if="form.orderType === 'Order' && form.shouldUpdate">
          Ticket Status
          <select v-model="form.ticketStatus">
            <option :value="2">In Progress</option>
            <option :value="8">Requester Action Required</option>
            <option :value="3">On Hold</option>
            <option :value="7">Escalated</option>
            <option :value="11">Ordered</option>
            <option :value="9">Project Complete</option>
            <option :value="4">Closed</option>
          </select>
        </label>
        <label
          v-if="form.orderType === 'Order' && form.shouldUpdate"
          for="message"
          class="pos__rel"
        >
          Message
          <div class="form--comments">
            <textarea
              id="message"
              v-model="form.response"
              rows="3"
              placeholder="new response here"
            />
            <canned @setMessage="setResponse" />
          </div>
        </label>
        <label for="#">
          <button class="btn btn__red" @click="resetForm">Clear</button>
          <button class="btn btn__green" @click="sendUpdates">Submit</button>
        </label>
      </div>
    </simplebar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createError } from "../../../../libs/flash-messages";
export default {
  data() {
    return {
      form: {
        orders: "",
        orderType: "",
        status: "",
        shouldUpdate: null,
        ticketStatus: 2,
        response: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    parsedIds() {
      return this.form.orders.split("\n").filter(item => item !== "");
    }
  },
  methods: {
    ...mapActions(["startSetSubLoading", "startBulkUpdate"]),
    setResponse(e) {
      this.form.response = e.message;
    },
    sendUpdates() {
      if (this.parsedIds.length <= 0)
        return createError(
          "Form Error",
          "You must put something in for the Order Numbers"
        );
      this.startSetSubLoading(true);
      const form = JSON.parse(JSON.stringify(this.form));
      form.orders = this.parsedIds;
      try {
        this.startBulkUpdate(form);
      } catch (err) {
        this.startSetSubLoading(false);
        createError("Couldn't Update", err);
      }
    },
    resetForm() {
      this.form.orders = "";
      this.form.orderType = "";
      this.form.status = "";
      this.form.shouldUpdate = null;
      this.form.ticketStatus = 2;
      this.form.response = "";
    }
  }
};
</script>

<style scoped lang="scss">
.align-top {
  align-items: start;
}
.text__normal {
  font-weight: normal !important;
}
.major--overflow {
  max-height: 325px;
}
.admin-quick--search {
  padding-right: 10px;
}
</style>