<template>
  <section class="admin-graph margin__md">
    <div class="flex__between">
      <h2>Reporting</h2>
      <router-link to="/admin/reporting" class="breadcrumb text__right">
        View All
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
    <!-- <iframe
      width="100%"
      height="300px"
      src="https://app.powerbi.com/reportEmbed?reportId=60d3419e-5aea-42f1-98d9-5d5213edf3a5&autoAuth=true&ctid=9b9c2d4d-bfe5-4326-a44b-e0d91ff34837&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
      frameborder="0"
      allowfullscreen="true"
    ></iframe>-->
    <iframe
      width="100%"
      height="300px"
      src="https://app.powerbi.com/view?r=eyJrIjoiYzdhY2IzMTUtZDU0My00NjhlLTg4OGQtNDk3ZTI5ZGI5ZDk1IiwidCI6IjliOWMyZDRkLWJmZTUtNDMyNi1hNDRiLWUwZDkxZmYzNDgzNyIsImMiOjN9"
      frameborder="0"
      allowfullscreen="true"
    ></iframe>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.graph-item {
  width: 300px;
  height: 200px;
  background-color: $sky;
  border: 2px dashed $black;
  margin: 15px;
}
</style>