<template>
  <section class="admin-products tile">
    <h2 class="mb-15">Product Search</h2>
    <div class="admin-quick--stats">
      <a href="/admin/products"><div class="flex__col stat__md">
        <span class="text__xl text__bold">{{ stats.total }}</span>
        <span>total products</span>
      </div></a>
      <div class="admin-quick--stats__sm">
        <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ stats.active }}</span>
          <span class="text__center text__sm">live products</span>
        </div>
        <!-- <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ stats.respa }}</span>
          <span class="text__center text__sm">respa "any order" products</span>
        </div> -->
        <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ stats.inventoried }}</span>
          <span class="text__center text__sm">in-house products</span>
        </div>
      </div>
    </div>
    <div class="tile-heading">
      <h3>Search</h3>
      <hr />
    </div>
    <div class="admin-quick--search">
      <label>
        Name
        <input type="text" v-model="form.name" placeholder="product name" />
      </label>
      <label>
        Status
        <select v-model="form.status">
          <option value>Any</option>
          <option value="live">Live</option>
          <option value="coming-soon">Coming Soon</option>
          <option value="test">Test</option>
        </select>
      </label>
      <!-- <label>
        Respa
        <select v-model="form.respa">
          <option value>Any</option>
          <option value="none">None</option>
          <option value="any">Any Order</option>
          <option value="cb-only">CB Only</option>
        </select>
      </label> -->
      <label>
        Featured
        <select v-model="form.isFeatured">
          <option value>Any</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </label>
      <label>
        <span></span>
        <router-link :to="link" class="btn btn__sm btn__green">Search</router-link>
      </label>
    </div>
  </section>
</template>

<script>
export default {
  props: ["products"],
  data() {
    return {
      form: {
        name: "",
        status: "",
        //respa: "",
        isFeatured: ""
      }
    };
  },
  computed: {
    link() {
      let some = false;
      for (let key in this.form) {
        if (this.form[key] !== "") {
          some = true;
        }
      }
      if (some) {
        let link = "/admin/products?";
        if (this.form.name) {
          link = `${link}name=${this.form.name}`;
        }
        if (this.form.status) {
          link = `${link}&status=${this.form.status}`;
        }
        // if (this.form.respa) {
        //   link = `${link}&respa=${this.form.respa}`;
        // }
        if (this.form.isFeatured) {
          link = `${link}&featured=${this.form.isFeatured}`;
        }
        return link;
      }
      return "/admin/products";
    },
    stats() {
      if (!this.products) return;
      return {
        total: this.products.length,
        active: this.products.filter(item => item.status === "live").length,
       // respa: this.products.filter(item => item.respaStatus === "any").length,
        inventoried: this.products.filter(item => item.isInventoried).length
      };
    }
  }
};
</script>

<style scoped lang="scss">
</style>