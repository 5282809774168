<template>
  <div class="hero" :class="[ left ? 'hero-left' : null ]">
    <router-link
      v-for="(num, index) in tiles"
      :key="num"
      :to="`${links[index]}`"
      class="hero--tile"
      :class="`hero--tile-${num}`"
    >
      <div class="hero--tile__img" :style="`background-image: url(${imgs[index]});`"></div>
      <p>
        {{ calls[index] }}
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeroSmall",
  props: {
    imgs: {
      type: Array,
      default: null
    },
    links: {
      type: Array,
      default: null
    },
    calls: {
      type: Array,
      default: null
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tiles: ["one", "two", "three"]
    };
  }
};
</script>

<style scoped lang="scss">
$areas: "one", "two", "three";
.hero {
  @include grid(
    $cols: repeat(1, 1fr),
    $rows: 300px 200px 200px,
    $col-gap: 10px,
    $row-gap: 10px
  );
  grid-template-areas:
    "one"
    "two"
    "three";
}

@each $area in $areas {
  .hero--tile-#{$area} {
    grid-area: #{$area};
  }
}
@media (min-width: $sm) {
  .hero {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 200px;
    grid-template-areas:
      "one one"
      "three two";
  }
}
@media (min-width: $md) {
  .hero {
    grid-template-columns: 4fr 5fr;
    grid-template-rows: 200px 200px;
    grid-template-areas:
      "two one"
      "three one";
  }
  .hero-left {
    grid-template-columns: 5fr 4fr;
    grid-template-areas:
      "one two"
      "one three";
  }
}
@media (min-width: $lg) {
  .hero {
    grid-template-rows: 250px 250px;
  }
}
</style>