<template>
  <section class="admin-processes margin__md">
    <!-- <h2 class="mb-15">Processes</h2> -->
    <HeroSmall :links="links" :calls="calls" :imgs="imgs" />
  </section>
</template>

<script>
import HeroSmall from "../../HeroSmall";
export default {
  components: {
    HeroSmall
  },
  data() {
    return {
      imgs: [
        "https://cdn.fbhl.com/WOW-Assets/flame-1208.png",
        "https://cdn.fbhl.com/WOW-Assets/clip-1062.png",
        "https://cdn.fbhl.com/WOW-Assets/clip-pre-delivery-processing.png"
      ],
      links: [
        "/admin/products/new",
        "/admin/users/new",
        "/admin/inventory-products"
      ],
      calls: ["New Product", "New User", "Product Inventory"]
    };
  }
};
</script>

<style scoped lang="scss">
</style>