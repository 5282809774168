<template>
  <section class="admin-orders tile">
    <h2 class="mb-15">User Search</h2>
    <div class="admin-quick--stats">
      <a href="/admin/users">
        <div class="flex__col stat__md">
          <span class="text__xl text__bold">{{ getUserStats.users }}</span>
          <span>total users</span>
        </div>
      </a>
      <div class="admin-quick--stats__sm">
        <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ getUserStats.active }}</span>
          <span class="text__center text__sm">active users</span>
        </div>
        <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ getUserStats.bulk }}</span>
          <span class="text__center text__sm">batch users</span>
        </div>
        <div class="flex__col stat__sm">
          <span class="text__lg text__bold">{{ getUserStats.admins }}</span>
          <span class="text__center text__sm">admins</span>
        </div>
      </div>
    </div>
    <div class="tile-heading">
      <h3>Search</h3>
      <hr />
    </div>
    <div class="admin-quick--search">
      <simplebar class="search--overflow" data-simplebar-auto-hide="false">
        <div class="search-fields">
          <label>
            Name
            <input type="text" v-model="form.name" placeholder="user name" />
          </label>
          <label>
            Email
            <input type="text" v-model="form.email" placeholder="user email" />
          </label>
          <label>
            Permission
            <select v-model="form.permission">
              <option value>Any</option>
              <option v-for="option in permissionOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </label>
          <label>
            Is Active
            <select v-model="form.isActive">
              <option value>Any</option>
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </label>
          <label>
            Batch Order Enabled
            <select v-model="form.bulkEnabled">
              <option value>Any</option>
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </label>
        </div>
      </simplebar>
      <label>
        <span></span>
        <router-link :to="link" class="btn btn__sm btn__green">Search</router-link>
      </label>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      permissionOptions: ["user", "admin", "super-admin", "management", "asm"],
      form: {
        name: "",
        email: "",
        permission: "",
        isActive: "",
        bulkEnabled: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getUserStats"]),
    link() {
      let some = false;
      for (let key in this.form) {
        if (this.form[key] !== "") {
          some = true;
        }
      }
      if (some) {
        let link = "/admin/users?";
        if (this.form.name) {
          link = `${link}name=${encodeURI(this.form.name)}`;
        }
        if (this.form.email) {
          link = `${link}&email=${this.form.email}`;
        }
        if (this.form.permission) {
          link = `${link}&permission=${this.form.permission}`;
        }
        if (this.form.isActive !== "") {
          link = `${link}&active=${this.form.isActive}`;
        }
        if (this.form.bulkEnabled) {
          link = `${link}&bulk=${this.form.bulkEnabled}`;
        }
        return link;
      }
      return "/admin/users";
    }
  }
};
</script>

<style scoped lang="scss">
</style>