<template>
  <section class="margin__md admin-quick__grid">
    <Search />
    <Update />
  </section>
</template>

<script>
// import { mapGetters } from "vuex";
import Search from "./Search";
import Update from "./Update";
export default {
  components: {
    Search,
    Update
  }
};
</script>

<style scoped lang="scss">
.admin-quick__grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
@media (min-width: $md) {
  .admin-quick__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .admin-quick__grid {
    grid-column-gap: 50px;
  }
}
</style>