<template>
  <section class="container">
    <h1 class="text__center margin__md">Admin</h1>
    <Graphs />
    <Orders />
    <Processes />
    <QuickSearch />
    <!-- <OrderItems /> -->
    <Other />
  </section>
</template>

<script>
import Graphs from "./Graphs";
import Orders from "./Orders";
// import OrderItems from "./OrderItems";
import Processes from "./Processes";
import QuickSearch from "./QuickSearch";
import Other from "./Other";
export default {
  components: {
    Graphs,
    Orders,
    // OrderItems,
    Processes,
    QuickSearch,
    Other
  },
  metaInfo: {
    title: "Admin | WOW"
  }
};
</script>

<style scoped lang="scss">
</style>