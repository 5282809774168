<template>
  <section class="admin-orders tile">
    <h2 class="mb-15">Order Search</h2>
    <div class="admin-quick--stats">
      <router-link
        :to="incompleteLink"
        class="flex__col stat__md link__none"
        name="Past orders incomplete"
      >
        <span
          class="text__xl text__bold"
        >{{ incompleteCount !== null ? incompleteCount : "loading..." }}</span>
        <span>submitted orders</span>
      </router-link>
      <div class="admin-quick--stats__sm">
        <router-link
          :to="preOrdersLink"
          class="flex__col stat__sm link__none"
          name="Past orders pre-ordered"
        >
          <span class="text__lg text__bold">{{ preOrders !== null ? preOrders : "loading..." }}</span>
          <span class="text__center text__sm">pre-orders</span>
        </router-link>
        <router-link
          :to="progressLink"
          class="flex__col stat__sm link__none"
          name="Past orders in progress"
        >
          <span
            class="text__lg text__bold"
          >{{ progressCount !== null ? progressCount : "loading..." }}</span>
          <span class="text__center text__sm">in progress</span>
        </router-link>
        <router-link
          :to="readyLink"
          class="flex__col stat__sm link__none"
          name="Past orders ready to ship"
        >
          <span class="text__lg text__bold">{{ readyCount !== null ? readyCount : "loading..." }}</span>
          <span class="text__center text__sm">ready to ship</span>
        </router-link>
      </div>
    </div>
    <div class="tile-heading">
      <h3>Search</h3>
      <hr />
    </div>
    <div class="admin-quick--search">
      <simplebar class="search--overflow" data-simplebar-auto-hide="false">
        <div class="search-fields">
          <label>
            Name
            <input type="text" v-model="form.name" placeholder="requester name" />
          </label>
          <label>
            Status
            <select v-model="form.status">
              <option value>Any</option>
              <option value="Started">Started</option>
              <option value="Submitted">Submitted</option>
              <option value="Ordered">Ordered</option>
              <option value="In Progress">In Progress</option>
              <option value="Awaiting Input">Awaiting Input</option>
              <option value="Ready to Ship">Ready to Ship</option>
              <option value="Sent">Sent</option>
              <option value="Beer">Beer</option>
              <option value="Pre-Order">Pre-Order</option>
              <option value="Complete">Complete</option>
              <option value="Admin Complete">Admin Complete</option>
            </select>
          </label>
          <label>
            Order Type
            <select v-model="form.orderType">
              <option value>Any</option>
              <option value="standard">Standard</option>
              <option value="bulk">Batch</option>
            </select>
          </label>
          <label>
            Is Favorite
            <select v-model="form.isFavorite">
              <option value>Any</option>
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </label>
          <label>
            Date Start
            <input
              type="date"
              v-mask="'####-##-##'"
              v-model="form.dateStart"
              placeholder="yyyy-mm-dd"
            />
          </label>
          <label>
            Date End
            <input
              type="date"
              v-mask="'####-##-##'"
              v-model="form.dateEnd"
              placeholder="yyyy-mm-dd"
            />
          </label>
        </div>
      </simplebar>
      <label>
        <span></span>
        <router-link :to="link" class="btn btn__sm btn__green">Search</router-link>
      </label>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        name: "",
        status: "",
        isFavorite: "",
        orderType: "",
        dateStart: "",
        dateEnd: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getOrders", "getOrdersLoaded"]),
    incompleteCount() {
      if (!this.getOrdersLoaded) return null;
      return this.getOrders.filter(order => {
        return order.status === "Submitted";
      }).length;
    },
    preOrders() {
      if (!this.getOrdersLoaded) return null;
      return this.getOrders.filter(order => {
        return order.status === "Pre-Order";
      }).length;
    },
    progressCount() {
      if (!this.getOrdersLoaded) return null;
      return this.getOrders.filter(order => {
        return (
          order.status !== "Complete" &&
          order.status !== "Admin Complete" &&
          order.status !== "Beer" &&
          order.status !== "Started" &&
          order.status !== "Submitted"
        );
      }).length;
    },
    readyCount() {
      if (!this.getOrdersLoaded) return null;
      return this.getOrders.filter(order => {
        return order.status === "Ready to Ship";
      }).length;
    },
    incompleteLink() {
      return "/admin/past-orders?status=incomplete&reset=true";
    },
    preOrdersLink() {
      return "/admin/past-orders?status=pre-order&reset=true";
    },
    progressLink() {
      return "/admin/past-orders?status=progress&reset=true";
    },
    readyLink() {
      return "/admin/past-orders?status=ready&reset=true";
    },
    link() {
      let some = false;
      for (let key in this.form) {
        if (this.form[key] !== "") {
          some = true;
        }
      }
      if (some) {
        let link = "/admin/past-orders?reset=true";
        if (this.form.name) {
          link = `${link}&name=${this.form.name}`;
        }
        if (this.form.status) {
          link = `${link}&status=${this.form.status}`;
        }
        if (this.form.orderType) {
          link = `${link}&ordertype=${this.form.orderType}`;
        }
        if (this.form.dateStart) {
          link = `${link}&datestart=${this.form.dateStart}`;
        }
        return link;
      }
      return "/admin/past-orders?reset=true";
    }
  }
};
</script>

<style scoped lang="scss">
</style>