<template>
  <section class="margin__md admin-quick__grid">
    <Products :products="getProducts" />
    <Users />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Products from "./Products";
import Users from "./Users";
export default {
  components: {
    Products,
    Users
  },
  computed: {
    ...mapGetters(["getProducts"])
  }
};
</script>

<style scoped lang="scss">
.admin-quick__grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
@media (min-width: $md) {
  .admin-quick__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .admin-quick__grid {
    grid-column-gap: 50px;
  }
}
</style>