<template>
  <section class="margin__md admin-other">
    <!-- <h2 class="mb-15">Other</h2> -->
    <div class="admin-other__grid">
      <router-link to="/admin/site-setup" class="hero--tile">
        <div
          class="hero--tile__img"
          style="background-image: url(https://cdn.fbhl.com/WOW-Assets/taxi-robot.png);"
        ></div>
        <p class="tile-link">
          Site Setup
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </p>
      </router-link>
      <router-link to="/admin/help" class="hero--tile">
        <div
          class="hero--tile__img"
          style="background-image: url(https://cdn.fbhl.com/WOW-Assets/clip-1395.png);"
        ></div>
        <p class="tile-link">
          Need Help?
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </p>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.admin-other__grid {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
.hero--tile {
  height: 250px;
}
@media (min-width: $md) {
  .admin-other__grid {
    grid-template-columns: 1fr 1fr;
  }
  .hero--tile {
    height: 350px;
  }
}
@media (min-width: $lg) {
  .hero--tile {
    height: 425px;
  }
}
</style>